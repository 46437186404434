<template>
  <v-chip
    v-on:click="pushToDetail"
    :class="[`font-weight-${fontWeight}`, 'custom-barcode', {'my-1': !dense, 'cursor-pointer': id, 'cursor-default': !id }]"
    :color="chipColor"
    outlined
    label
    :small="small"
  >
    {{ barcode }}
  </v-chip>
</template>

<script>
export default {
  props: {
    barcode: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
    },
    route: {
      type: String,
    },
    id: {
      type: Number,
    },
    fontWeight: {
      type: Number,
      default: 600, 
    },
    chipColor: {
      type: String,
      default: "chip-custom-blue"
    }
  },
  methods: {
    pushToDetail() {
      let detailID = this.lodash.toSafeInteger(this.id);
      if (this.route && detailID > 0) {
        this.$router.push(
          this.getDefaultRoute(this.route, {
            params: { id: detailID },
          })
        );
      }
    },
  },
};
</script>
