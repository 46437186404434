<template>
  <div class="product-create create-form" v-if="getPermission('part:create')">
    <v-form
      ref="taggingForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <h1 class="form-title d-flex">
                    <template v-if="taggingId">Update</template>
                    <template v-else>Create</template> Tagging - 
                    Equipment ID &nbsp;
                    <Barcode v-if="taggingCreate"
                      class="cursor-pointer ml-2"
                      :barcode="taggingCreate && taggingCreate.equipment_id"
                    ></Barcode>
                  </h1>
                </v-col>
                <v-col cols="6" class="pt-0 text-right">
                  <v-btn
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit"
                  >
                    <template v-if="taggingId">Update</template>
                    <template v-else>Save</template> Tagging Label
                  </v-btn>
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    outlined
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <TaggingCreate
              :taggingId="taggingId"
              :barcodeData="barcode"
              :updateMode="updateMode"
              :taggingCreateData="taggingCreate"
              v-on:saveTagging="updateTagging"
            ></TaggingCreate>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";

import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
// import PartCreate from "@/view/pages/part/partials/Create.vue";
import TaggingCreate from "@/view/pages/tagging/partials/Create.vue";
// import TaggingCreateView from "@/view/pages/tagging/partials/Create.vue"
//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CommonMixin, ProductMixin],
  name: "tagging-create",
  title: "Create Tagging",
  data() {
    return {
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      parttCreate: null,
      taggingCreate: null,
      taggingId: null,
      duplicateId: null,
    };
  },
  components: {
    TaggingCreate,
    Barcode
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateTagging(param) {
      
      this.taggingCreate = param;
    },
    getTagging(taggingId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (taggingId && taggingId > 0) {
          _this.$store
            .dispatch(GET, { url: "tagging/" + taggingId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },

    onSubmit() {
      const _this = this;
      if (!_this.$refs.taggingForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "tagging";
      let Message = "Created.";

      if (_this.taggingId && _this.taggingId > 0) {
        requestType = PUT;
        requestURL = "tagging/" + _this.taggingId;
        Message = "Updated.";
      }
      
      const TaggingData = _this.taggingCreate;  
      const formData = {
				serial_no: TaggingData.serialNo?TaggingData.serialNo:null,
				equipement_type: TaggingData.equipmentType?TaggingData.equipmentType:null,
				job_order_no: TaggingData.jobOrderNo?TaggingData.jobOrderNo:null,
				date: TaggingData.date?TaggingData.date:null,
				total_quantity: TaggingData.totalQuantity?TaggingData.totalQuantity:0,
				tagging_profile_logo: TaggingData.tagging_profile_logo?TaggingData.tagging_profile_logo:null,
				product_type:TaggingData.product_type?TaggingData.product_type:'taggings',
        customer_name:TaggingData.customer_name?TaggingData.customer_name:null,
        quotation_number:TaggingData.quotation_number?TaggingData.quotation_number:null,
			};
      if(_this.duplicateId){
        formData.duplicate=_this.duplicateId;
      } 

      try {

        if(!formData.job_order_no){
          _this.$store.commit(SET_ERROR, [{ model: true, message: "Job order no required" }]);
          return false;
        } 

        _this.formLoading = true;
        _this.$store
        .dispatch(requestType, { url: requestURL, data: formData })
        .then(async (response) => {
          _this.taggingId = response.data ? response.data.id : null;

          _this.$router.push(
            _this.getDefaultRoute("tagging", {
              query: {
                status: "all",
              },
            })
          );
        });
        
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Success ! Taggings has been "+Message },
        ]);
      } catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				//_this.pageLoading = false;
        _this.formLoading = false;
			}
        // .catch((error) => {
        //   _this.logError(error);
        // })
        // .finally(() => {
        //   _this.formLoading = false;
        // });
    },
    loadContent() {
      const _this = this;
      _this
        .getTagging(_this.taggingId)
        .then((response) => {
          
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Tagging", route: "tagging" },
              { title: "Update" },
            ]);

            _this.taggingCreate = {
              equipment_id:response.equipment_id,
              serialNo: response.serial_no,
              equipmentType: response.equipement_type,
              date: response.date,
              jobOrderNo: response.job_order_no,
              totalQuantity: response.total_quantity,
              product_type: response.product_type,
              image: response.image,
              customer_name:response.customer_name,
              quotation_number:response.quotation_number,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      
      const _this = this;
      _this
        .getTagging(_this.duplicateId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.taggingCreate = {
              serialNo: response.serial_no,
              equipmentType: response.equipement_type,
              date: response.date,
              jobOrderNo: response.job_order_no,
              totalQuantity: response.total_quantity,
              product_type: response.product_type,
              image: response.image,
              customer_name:response.customer_name,
              quotation_number:response.quotation_number,
              equipment_id:null,
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.taggingId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tagging Label", route: "tagging" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.tagging.update" ||
      _this.$route.name == "engineer.tagging.update"
    ) {
      _this.updateMode = true;

      if (_this.taggingId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getTagging(_this.taggingId);
    });
  },
};
</script>
