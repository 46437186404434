<template>
  <v-layout v-if="getPermission('service:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="12" sm="12">
      <div v-if="errors?.length" class="iv-custom-field pt-0 width-100">
        <div
          class="alert fade alert-danger"
          role="alert"
          v-bind:class="{ show: errors?.length }"
        >
          <div v-for="(error, i) in errors" :key="i" class="alert-text">
            {{ error }}
          </div>
        </div>
      </div>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow service-create-height"
        style="max-height: 90vh; position: relative"
      >
        <!-- ------------------------start tagging---------------------->
        <v-col md="12" class="pb-0">
          <v-col md="12">
            <v-row>
              <v-col md="8">
                <v-card
                  flat
                  style="
                    height: 325px;
                  "
                >
                <!-- border-right: 0.5px solid rgb(235 235 237); -->
                  <v-card-text>
                    <v-row>
                      <v-col md="12">
                        <v-form
                          ref="customerForm"
                          v-model.trim="formValid"
                          lazy-validation
                          v-on:submit.stop.prevent="updateOrCreate"
                        >
                          <v-row>
                            <!-- <v-col md="3" class="my-auto py-0">
                              <label
                                for="Equipment-ID"
                                class=" pl-2 font-weight-700 font-size-16 width-100"
                                >Equipment ID</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                id="Equipment-ID"
                                dense
                                filled
                                color="red"
                                label="Equipment ID"
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model="taggingCreate.equipment_id"
                                readonly
                              ></v-text-field>
                            </v-col> -->
                            <v-col md="3" class="my-auto py-0">
                              <label
                                for="Serial-No"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Serial No</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                dense
                                filled
                                color="cyan"
                                label="Serial No"
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model="taggingCreate.serialNo"
                              ></v-text-field>
                              <!-- :rules="[
                                  validateRules.required(
                                    taggingCreate.serialNo,
                                    'Serial No'
                                  ),
                                ]" -->
                            </v-col>
                            <!-- <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="Equipment-Type"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mt-2"
                                >Equipment Type</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-autocomplete
                                dense
                                filled
                                color="cyan"
                                :items="['list1', 'list2']"
                                label="Equipment Type"
                                solo
                                flat
                                append-outer-icon="mdi-cog"
                                v-on:click:append-outer="UomData"
                                item-text="text"
                                item-value="text"
                                class="pl-2 width-100"
                                v-model="taggingCreate.equipmentType"
                              >
                                <template v-slot:no-data>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        v-html="'No Unit(s) Found.'"
                                      ></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                              </v-autocomplete>
                            </v-col> -->
                            <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="date"
                                class="pl-2 font-weight-700 font-size-16 width-100"
                                >Received Date</label
                              >
                            </v-col>
                            <v-col md="9" class="my-auto py-0 mb-3">
                              <!-- {{ taggingCreate.date }} -->
                              <Datepicker
                                customClass="ml-1"
                                dense
                                filled
                                solo
                                flat
                                :default-date="taggingCreate.date"
                                 v-model="taggingCreate.date"
                                v-on:update:date-picker="setDefaultDate($event)"
                                ></Datepicker>
                                <!-- v-model="taggingCreate.date" -->
                            </v-col>
                            <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="phone-number"
                                class="pr-2 pl-2 font-weight-700 required font-size-16 width-100"
                                >Job Order No.</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                
                                dense 
                                filled
                                color="red"
                                label="Job Order No."
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model.trim="taggingCreate.jobOrderNo"
                                type="input"
                                maxlength="15"
                                :rules="[validateRules.required(taggingCreate.jobOrderNo, 'Job number')]"
                              ></v-text-field>
                              <!-- <TextInput

																	:counter="150"
																	id="name"
                                  class="pl-2 width-100"
																	placeholder="Job Order No"
																	v-model="taggingCreate.jobOrderNo"
																></TextInput> -->
                            </v-col>
                            <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="customer-name"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Customer Name</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                dense
                                filled
                                color="cyan"
                                label="Customer Name"
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model="taggingCreate.customer_name"
                              ></v-text-field>
                            </v-col>
                            <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="quotation_number"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Quotation Number</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                dense
                                filled
                                color="cyan"
                                label="Quotation Number"
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model="taggingCreate.quotation_number"
                              ></v-text-field>
                            </v-col>

                            <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="equipment-type"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                                >Equipment Type</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                dense
                                filled
                                color="cyan"
                                label="Equipment Type"
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model="taggingCreate.equipmentType"
                              ></v-text-field>
                            </v-col>
                            
                            <!-- <v-col md="3" class="my-auto py-0 mb-3">
                              <label
                                for="address-line-1"
                                class="pr-2 pl-2 font-weight-700 font-size-16 width-100 mt-2"
                                >Total Quantity</label
                              >
                            </v-col>
                            <v-col md="9" class="py-0 mb-3">
                              <v-text-field
                                dense
                                filled
                                color="cyan"
                                label="Total Quantity"
                                solo
                                flat
                                class="pl-2 width-100"
                                v-model="taggingCreate.totalQuantity"
                              ></v-text-field>
                            </v-col> -->
                          </v-row>
                        </v-form>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- <v-col md="3" class="">
                <v-col
                  md="12"
                  align="center"
                  justify="center"
                  class="px-6 pb-6"
                >
                  <template>
                    <v-img
                      :lazy-src="$defaultProfileImage"
                      :src="
                        getProfileImage
                          ? getProfileImage
                          : 'https://fsm-v3.businessthrust.com/media/default/no-image.png'
                      "
                      max-height="150"
                      max-width="150"
                      height="150px"
                      width="150px"
                      class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
                    >
                    </v-img>
                    <div class="margin-auto" align="end">
                      <div class="d-none">
                        <v-file-input
                          ref="uploadImage"
                          v-on:change="updateProfileImage"
                          :rules="imageRules"
                          accept="image/png, image/jpeg, image/jpg"
                          v-model="taggingCreate.tagging_profile_logo"
                        ></v-file-input>
                      </div>
                    </div>
                  </template>
                  <v-flex class="d-flex justify-content-center mt-4">
                    <img
                      max-width="250"
                      width="250"
                      src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/IMPROVED/files/barcodes/A-000018.png"
                    />
                  </v-flex>
                  <p style="font-weight: 600">3985734895</p>
                </v-col>
                <v-col md="12" class="pa-0" v-show="false">
                  <v-row class="pa-0" style="margin: 0% !important">
                    <v-col
                      md="2"
                      sm="3"
                      align="center"
                      justify="center"
                      class="pa-0 mt-2"
                    >
                      <v-file-input
                        id="product-image"
                        accept="image/png, image/jpeg, image/bmp"
                        ref="fileInput"
                        class="d-none"
                        v-on:change="handleFilesUpload()"
                      >
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
              <v-col md="1">
                <div>
                  <span v-if="getProfileImage">
                    <v-icon
                      v-on:click="selectImage"
                      style="cursor: pointer; font-size: 35px"
                      color="cyan"
                      >mdi-refresh</v-icon
                    ></span
                  >
                  <span v-else>
                    <v-icon
                      v-on:click="selectImage"
                      style="cursor: pointer; font-size: 35px"
                      color="cyan"
                      >add_a_photo</v-icon
                    ></span
                  >
                </div>
              </v-col> -->
            </v-row>
          </v-col>

          <v-layout class="mb-2 mt-4">
            <v-flex md6 v-if="false">
              <label
                class="pl-2 font-weight-700 font-size-16 width-100 required"
                >UOM
              </label>
              <v-autocomplete
                dense
                filled
                color="cyan"
                item-color="cyan"
                :items="unitList"
                label="UOM"
                solo
                flat
                append-outer-icon="mdi-cog"
                v-on:click:append-outer="UomData"
                item-text="text"
                item-value="text"
                class="pl-2 width-100"
                v-model.trim="taggingCreate.unit"
                :rules="[validateRules.required(taggingCreate.unit, 'UOM')]"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Unit(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-flex>

            <ManageServiceUnit
              :u-dialog="serviceUnitDialog"
              :unit="unitList"
              :exist-unit="taggingCreate.unit"
              v-on:close-dialog="serviceUnitDialog = false"
              v-on:get-product-unit="getOptions"
            ></ManageServiceUnit>
          </v-layout>
          <br />
        </v-col>
        <!-- ------------------------tagging---------------------- -->
      </perfect-scrollbar>
    </v-col>
    <v-col
      v-if="false"
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
    <ManageCategory
      service
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      :exist-category="taggingCreate.category"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib//warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
// import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageServiceUnit from "@/view/pages/partials/Manage-Service-Unit.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";
//import TextInput from "@/view/components/TextInput";

import {
  QUERY,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";
//import { map, compact } from "lodash";

export default {
  name: "tagging-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    taggingId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    duplicateId: {
      type: Number,
      default: null,
    },
    taggingCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  data() {
    return {
      rules:[v => v?.length <= 15 || 'Max 15 characters'],
      timeoutLimit: 500,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      serviceUnitDialog: false,
      unitList: new Array(),
      barcode: null,
      manageCategoryDialog: false,
      warrantyActive: false,
      defaultDate: moment().startOf("day").format("YYYY-MM-DD"),
      taggingCreate: {
        customer_name:null,
        quotation_number:null,
        equipment_id:null,
        serialNo: null,
        equipmentType: null,
        date: null,
        jobOrderNo: null,
        totalQuantity: null,
        product_type: "taggings",
        tagging_profile_logo: null,
      },
      imageRules: [],
      fieldDescriptions: [
        {
          field: "tagging_type",
          title: "tagging Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    // FileTemplate,
    ManageCategory,
    ManageServiceUnit,
    Datepicker,
  //  TextInput,
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    taggingCreateData: {
      deep: true,
      //immediate: true,
      handler(param) {
        this.taggingCreate = param;

        // console.log({param})

        // if (this.lodash.isObject(this.taggingCreate.unit)) {
        //   this.taggingCreate.unit = this.taggingCreate.unit;
        // }

        //this.productCreate.barcode = this.barcode;

        // if (param && this.lodash.isEmpty(param.warranty)) {
        //   this.warrantyActive = true;
        //   this.taggingCreate.warranty.push({
        //     id: null,
        //     value: null,
        //     field: null,
        //   });
        // }

        // this.$nextTick(() => {
        //   if (param.warranty && param.warranty.length) {
        //     const item = compact(map(param.warranty, "id"));
        //     if (item && item.length) {
        //       this.warrantyActive = true;
        //     }
        //   }
        // });
      },
    },
    taggingCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveTagging", _this.taggingCreate);
        }, _this.timeoutLimit);
        // if (_this.duplicateId) {
        //   this.taggingCreate.barcode = this.barcode;
        // }
      },
    },
    "taggingCreate.product_type"() {
      if(!this.duplicateId && !this.taggingId ){
        this.getOptions();
      }
    },
  },
  methods: {
    setDefaultDate(date) {
      this.defaultDate = moment(date).startOf("day").format("YYYY-MM-DD");
    },
    manageLimitDescription(e) {
      if (
        this.taggingCreate.description &&
        this.taggingCreate.description?.length > 499
      ) {
        e.preventDefault();
      }
    },
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          _this.taggingCreate.tagging_profile_logo =
            _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    /*  onPaste(e){
         let coppied = e.clipboardData.getData("Text")
		 if(e && coppied && coppied.length){
			this.productCreate.description = coppied.substring(0, 500)
			e.preventDefault();
		 } 
		}, */
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.taggingCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval?.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.taggingCreate.description = trimValue;
        e.preventDefault();
      }
    },
    UomData() {
      this.serviceUnitDialog = true;
      this.getOptions();
    },
    CategoryData() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    updateImages(param) {
      this.taggingCreate.images = param;
    },
    pushWarranty() {
      this.taggingCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "tagging/" + _this.taggingId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.taggingCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.taggingCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "tagging/options",
          data: { product_type: _this.taggingCreate.product_type },
        })
        .then((response) => {
          console.log({response})
         
          if(response.data.barcode){
            _this.taggingCreate.equipment_id=response.data.barcode;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.supplier
          ) {
            _this.supplierList = response.data.supplier;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.taggingCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.taggingCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.taggingCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.taggingCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;

            //  console.log(_this.unitList,"_this.unitList");
          }
          if (
            !_this.taggingId &&
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode
          ) {
            //_this.barcode = response.data.barcode;
            //_this.taggingCreate.barcode = response.data.barcode;
          }
          
          /* if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
            _this.barcodeSetting = response.data.barcode_setting;
            _this.productCreate.description = _this.barcodeSetting.description;
          } */
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  computed: {
    getProfileImage() {
      return this.lodash.isEmpty(this.taggingCreate.tagging_profile_logo) ===
        false
        ? this.taggingCreate.tagging_profile_logo.file.url
        : null;
    },
  },
  mounted() {
    if(!this.duplicateId && !this.taggingId ){
      this.getOptions();
    }
    
  },
};
</script>
